import type { NavigationItemCollectionFragment } from '~/lib/ContentfulGraphqlService';

export function isNavigationItemCollectionFragment(
  input: unknown
): input is NavigationItemCollectionFragment {
  return (
    typeof input === 'object' &&
    input != null &&
    '__typename' in input &&
    input.__typename === 'NavigationItem' &&
    'navigationItemsCollection' in input
  );
}
