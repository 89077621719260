import type {
  MainNavigationItemInterface,
  NavigationItemInterface
} from '@hypercodestudio/basler-components/dist/models/navigation/NavigationItem';
import { isDefined } from '~/utils/guards/isDefined';
import type { NavigationItemFragment } from '~/lib/ContentfulGraphqlService';
import { isNavigationItemCollectionFragment } from '~/utils/guards/isNavigationItemCollectionFragment';

export function useCreateMainNavItem(
  item: NavigationItemFragment | null | undefined,
  isItemActive: boolean,
  depth = 0
): MainNavigationItemInterface | undefined {
  if (
    !item ||
    (isDefined(item.showInNavigation) && !item.showInNavigation) ||
    depth > 3
  ) {
    return;
  }

  const subItems = isNavigationItemCollectionFragment(item)
    ? item.navigationItemsCollection?.items
    : undefined;

  const moreLink =
    item.moreLink && item.moreLinkText
      ? {
          link: useBuildLinkInterface(item.moreLink) ?? {},
          text: item.moreLinkText
        }
      : undefined;

  const items = subItems
    ?.map((n) => useCreateMainNavItem(n, false, depth + 1))
    .filter(isDefined);

  return {
    items: items?.length ? items : undefined,
    altText: item.altText ?? undefined,
    isTwoColumns: item.isTwoColumns ?? undefined,
    moreLink,
    specialLink:
      item.specialLink && item.specialLinkText
        ? {
            link: useBuildLinkInterface(item.specialLink) ?? {},
            text: item.specialLinkText
          }
        : undefined,
    hideOnDesktop: item.hideOnDesktop ?? undefined,
    hideOnMobile: item.hideOnMobile ?? undefined,
    text: item.linkText ?? '',
    link:
      item.internalPage || item.externalLink
        ? useBuildLinkInterface(item)
        : undefined,
    isActive: isItemActive
  } satisfies MainNavigationItemInterface;
}

export function useCreateNavItem(
  item?: NavigationItemFragment | null,
  withAbsolutePath = false,
  ignoreShowInNavigation = false
): NavigationItemInterface | undefined {
  if (
    !item ||
    (isDefined(item.showInNavigation) &&
      !item.showInNavigation &&
      !ignoreShowInNavigation)
  ) {
    return;
  }

  const subItems = isNavigationItemCollectionFragment(item)
    ? item.navigationItemsCollection?.items
    : undefined;

  return {
    items: subItems
      ?.map((n) => useCreateNavItem(n, withAbsolutePath))
      .filter(isDefined),
    text: item.linkText ?? '',
    link: useBuildLinkInterface(item, withAbsolutePath),
    isActive: false
  };
}
